.made-by {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: rem(10);

  color: var(--footer-text-secondary);
  text-transform: uppercase;
  overflow: hidden;

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include hover {
    color: var(--accent-color-dark);
    
    &>svg {
      fill: var(--accent-color-dark);
    }
  }

  &::before {
    @include pseudo(16) {}
  }

  &__logo {
    width: rem(32.8);
    height: rem(12);

    fill: var(--footer-text-secondary);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(32.8);
      height: big(12);
    }
  }

  &__text {
    font-size: rem(14);

    @include mediaBigDesktop {
      font-size: big(14);
    }
  }
}
