@import '../../mixins';

.footer {
  --footer-text-primary: var(--text-light-primary);
  --footer-text-secondary: var(--text-light-secondary);
  --stroke-footer: var(--stroke-light);
  --bg-footer: var(--bg-white);

  position: relative;
  background-color: var(--footer-background);
  
  &_dark-text {
    --footer-text-primary: var(--text-dark-primary);
    --footer-text-secondary: var(--text-dark-secondary);
    --stroke-footer: var(--stroke-dark);
    --bg-footer: var(--bg-dark);
  }
  
  &-primary {
    padding: rem(50) 0;

    @include mediaBigDesktop {
      padding: big(50) 0;
    }

    @include mediaTablet {
      padding: rem(60) 0;
    }

    @include mediaMobile {
      padding: rem(50) 0 rem(30);
    }

    &__container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }

      @include mediaTablet {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
    }

    &__block {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: rem(15);

      @include mediaBigDesktop {
        gap: big(15);
      }

      &>.logo {
        @include mediaMobile {
          height: rem(44);
        }

        & .logo {
          &__image {
            @include mediaMobile {
              height: rem(44);
            }
          }
        }
      }

      &:first-child {
        grid-column: 1/3;

        gap: rem(20);

        @include mediaBigDesktop {
          gap: big(20);
        }

        @include mediaTablet {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: rem(10);
        }
      }

      &_contact {
        grid-column: 9/13;

        @include mediaTablet {
          & .footer-dropdown {
            padding-bottom: 0;

            border-bottom: none;
          }
        }
      }
    }

    &__menu {
      grid-column: 3/9;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }

      @include mediaTablet {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
      }

      & .footer-primary {
        &__block {
          &:nth-child(3n+1) {
            grid-column: 1/3;
          }

          &:nth-child(3n+2) {
            grid-column: 3/5;
          }

          &:nth-child(3n+3) {
            grid-column: 5/7;
          }
        }
      }
    }
  }

  &-powered {
    background-color: var(--bg-footer-bottom, #00000033);
    padding: rem(18) 0 rem(19);

    @include mediaBigDesktop {
      padding: big(18) 0 big(19);
    }

    @include mediaMobile {
      padding: rem(15) 0;
    }

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      column-gap: rem(20);
      row-gap: rem(5);

      @include mediaBigDesktop {
        column-gap: big(20);
        row-gap: big(5);
      }
    }

    &__block {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: rem(20);
      row-gap: rem(5);

      @include mediaBigDesktop {
        column-gap: big(20);
        row-gap: big(5);
      }
    }

    &__link {
      position: relative;

      color: var(--footer-text-secondary);

      &::before {
        @include pseudo(10) {}
      }
      
      @include hover {
        color: var(--accent-color-dark);
      }
    }
  }

  &__btn-top {
    width: rem(50);
    height: rem(50);
    position: fixed;
    right: rem(30);
    bottom: rem(-100);
    z-index: 999;
    transition: bottom var(--animation-timing-medium);

    @include mediaBigDesktop {
      width: big(50);
      height: big(50);
      right: big(30);
      bottom: big(-100);
    }

    @include mediaTablet {
      width: rem(45);
      height: rem(45);
      right: rem(20);
      bottom: rem(-80);
    }

    @include mediaMobile {
      width: rem(40);
      height: rem(40);
      right: rem(10);
      bottom: rem(-60);
    }

    .button__icon {
      fill: var(--accent-color);
    }

    &.to-top_visible {
      bottom: rem(100);

      @include mediaBigDesktop {
        bottom: big(100);
      }

      @include mediaTablet {
        bottom: rem(80);
      }

      @include mediaMobile {
        bottom: rem(70);
      }
    }

    &.scroll-button_up {
      bottom: rem(150);

      @include mediaMobile {
        bottom: rem(140);
      }
    }
  }
}

@import "made-by",
"payment-methods",
"footer-dropdown",
"contact-list",
"social-media"
