.footer {
  --footer-text-primary: var(--text-light-primary);
  --footer-text-secondary: var(--text-light-secondary);
  --stroke-footer: var(--stroke-light);
  --bg-footer: var(--bg-white);
  position: relative;
  background-color: var(--footer-background); }
  .footer_dark-text {
    --footer-text-primary: var(--text-dark-primary);
    --footer-text-secondary: var(--text-dark-secondary);
    --stroke-footer: var(--stroke-dark);
    --bg-footer: var(--bg-dark); }
  .footer-primary {
    padding: 3.125rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .footer-primary {
        padding: 1.95312vw 0; } }
    @media screen and (max-width: 87.5rem) {
      .footer-primary {
        padding: 3.75rem 0; } }
    @media screen and (max-width: 39.375rem) {
      .footer-primary {
        padding: 3.125rem 0 1.875rem; } }
    .footer-primary__container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer-primary__container {
          gap: 0.78125vw; } }
      @media screen and (max-width: 87.5rem) {
        .footer-primary__container {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          gap: 0; } }
    .footer-primary__block {
      width: 100%;
      height: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      gap: 0.9375rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer-primary__block {
          gap: 0.58594vw; } }
      @media screen and (max-width: 39.375rem) {
        .footer-primary__block > .logo {
          height: 2.75rem; } }
      @media screen and (max-width: 39.375rem) {
        .footer-primary__block > .logo .logo__image {
          height: 2.75rem; } }
      .footer-primary__block:first-child {
        grid-column: 1/3;
        gap: 1.25rem; }
        @media screen and (min-width: 160.0625rem) {
          .footer-primary__block:first-child {
            gap: 0.78125vw; } }
        @media screen and (max-width: 87.5rem) {
          .footer-primary__block:first-child {
            width: 100%;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            gap: 0.625rem; } }
      .footer-primary__block_contact {
        grid-column: 9/13; }
        @media screen and (max-width: 87.5rem) {
          .footer-primary__block_contact .footer-dropdown {
            padding-bottom: 0;
            border-bottom: none; } }
    .footer-primary__menu {
      grid-column: 3/9;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer-primary__menu {
          gap: 0.78125vw; } }
      @media screen and (max-width: 87.5rem) {
        .footer-primary__menu {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          gap: 0; } }
      .footer-primary__menu .footer-primary__block:nth-child(3n+1) {
        grid-column: 1/3; }
      .footer-primary__menu .footer-primary__block:nth-child(3n+2) {
        grid-column: 3/5; }
      .footer-primary__menu .footer-primary__block:nth-child(3n+3) {
        grid-column: 5/7; }
  .footer-powered {
    background-color: var(--bg-footer-bottom, #00000033);
    padding: 1.125rem 0 1.1875rem; }
    @media screen and (min-width: 160.0625rem) {
      .footer-powered {
        padding: 0.70312vw 0 0.74219vw; } }
    @media screen and (max-width: 39.375rem) {
      .footer-powered {
        padding: 0.9375rem 0; } }
    .footer-powered__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-column-gap: 1.25rem;
         -moz-column-gap: 1.25rem;
              column-gap: 1.25rem;
      row-gap: 0.3125rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer-powered__container {
          -webkit-column-gap: 0.78125vw;
             -moz-column-gap: 0.78125vw;
                  column-gap: 0.78125vw;
          row-gap: 0.19531vw; } }
    .footer-powered__block {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-column-gap: 1.25rem;
         -moz-column-gap: 1.25rem;
              column-gap: 1.25rem;
      row-gap: 0.3125rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer-powered__block {
          -webkit-column-gap: 0.78125vw;
             -moz-column-gap: 0.78125vw;
                  column-gap: 0.78125vw;
          row-gap: 0.19531vw; } }
    .footer-powered__link {
      position: relative;
      color: var(--footer-text-secondary); }
      .footer-powered__link::before {
        content: "";
        position: absolute;
        top: -0.625rem;
        left: -0.625rem;
        width: calc(100% + 0.625rem * 2);
        height: calc(100% + 0.625rem * 2);
        background-color: transparent;
        opacity: 0; }
        @media screen and (min-width: 160.0625rem) {
          .footer-powered__link::before {
            top: -0.39062vw;
            left: -0.39062vw;
            width: calc(100% + 0.39062vw * 2);
            height: calc(100% + 0.39062vw * 2); } }
      @media (any-hover: hover) {
        .footer-powered__link:hover {
          color: var(--accent-color-dark); } }
  .footer__btn-top {
    width: 3.125rem;
    height: 3.125rem;
    position: fixed;
    right: 1.875rem;
    bottom: -6.25rem;
    z-index: 999;
    -webkit-transition: bottom var(--animation-timing-medium);
    -o-transition: bottom var(--animation-timing-medium);
    transition: bottom var(--animation-timing-medium); }
    @media screen and (min-width: 160.0625rem) {
      .footer__btn-top {
        width: 1.95312vw;
        height: 1.95312vw;
        right: 1.17188vw;
        bottom: -3.90625vw; } }
    @media screen and (max-width: 87.5rem) {
      .footer__btn-top {
        width: 2.8125rem;
        height: 2.8125rem;
        right: 1.25rem;
        bottom: -5rem; } }
    @media screen and (max-width: 39.375rem) {
      .footer__btn-top {
        width: 2.5rem;
        height: 2.5rem;
        right: 0.625rem;
        bottom: -3.75rem; } }
    .footer__btn-top .button__icon {
      fill: var(--accent-color); }
    .footer__btn-top.to-top_visible {
      bottom: 6.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer__btn-top.to-top_visible {
          bottom: 3.90625vw; } }
      @media screen and (max-width: 87.5rem) {
        .footer__btn-top.to-top_visible {
          bottom: 5rem; } }
      @media screen and (max-width: 39.375rem) {
        .footer__btn-top.to-top_visible {
          bottom: 4.375rem; } }
    .footer__btn-top.scroll-button_up {
      bottom: 9.375rem; }
      @media screen and (max-width: 39.375rem) {
        .footer__btn-top.scroll-button_up {
          bottom: 8.75rem; } }

.made-by {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.625rem;
  color: var(--footer-text-secondary);
  text-transform: uppercase;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .made-by {
      gap: 0.39062vw; } }
  @media (any-hover: hover) {
    .made-by:hover {
      color: var(--accent-color-dark); }
      .made-by:hover > svg {
        fill: var(--accent-color-dark); } }
  .made-by::before {
    content: "";
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: calc(100% + 1rem * 2);
    height: calc(100% + 1rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .made-by::before {
        top: -0.625vw;
        left: -0.625vw;
        width: calc(100% + 0.625vw * 2);
        height: calc(100% + 0.625vw * 2); } }
  .made-by__logo {
    width: 2.05rem;
    height: 0.75rem;
    fill: var(--footer-text-secondary);
    -webkit-transition: fill var(--animation-timing) var(--cubic-bezier);
    -o-transition: fill var(--animation-timing) var(--cubic-bezier);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .made-by__logo {
        width: 1.28125vw;
        height: 0.46875vw; } }
  .made-by__text {
    font-size: 0.875rem; }
    @media screen and (min-width: 160.0625rem) {
      .made-by__text {
        font-size: 0.54688vw; } }

.payment-methods {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .payment-methods {
      gap: 0.39062vw; } }
  .payment-methods__list {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 0.3125rem;
    list-style: none; }
    @media screen and (min-width: 160.0625rem) {
      .payment-methods__list {
        gap: 0.19531vw; } }
  .payment-methods__text {
    color: var(--footer-text-secondary); }
  .payment-methods__item {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    border-radius: var(--radius-secondary);
    overflow: hidden; }
    .payment-methods__item::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: var(--bg-footer);
      opacity: 0.05; }
  .payment-methods__image {
    position: relative;
    z-index: 1;
    width: auto;
    height: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .payment-methods__image {
        height: 0.9375vw; } }

.footer-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.9375rem; }
  @media screen and (min-width: 160.0625rem) {
    .footer-dropdown {
      gap: 0.58594vw; } }
  .footer-dropdown__title {
    color: var(--footer-text-primary); }
  .footer-dropdown__icon {
    width: 1rem;
    height: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    fill: var(--bg-dark); }
    @media screen and (min-width: 160.0625rem) {
      .footer-dropdown__icon {
        width: 0.625vw;
        height: 0.625vw; } }
  .footer-dropdown__list {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0.625rem;
    list-style: none; }
    @media screen and (min-width: 160.0625rem) {
      .footer-dropdown__list {
        gap: 0.39062vw; } }
  .footer-dropdown__link {
    position: relative; }
    .footer-dropdown__link::before {
      content: "";
      position: absolute;
      top: -0.3125rem;
      left: -0.3125rem;
      width: calc(100% + 0.3125rem * 2);
      height: calc(100% + 0.3125rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .footer-dropdown__link::before {
          top: -0.19531vw;
          left: -0.19531vw;
          width: calc(100% + 0.19531vw * 2);
          height: calc(100% + 0.19531vw * 2); } }
    .footer-dropdown__link:not(.link_accent) {
      color: var(--footer-text-secondary); }
      @media (any-hover: hover) {
        .footer-dropdown__link:not(.link_accent):hover {
          color: var(--accent-color-dark); } }
    .footer-dropdown__link.link_accent {
      color: var(--elements-orange); }
  .footer-dropdown__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0.9375rem; }
    @media screen and (min-width: 160.0625rem) {
      .footer-dropdown__content {
        gap: 0.58594vw; } }
    .footer-dropdown__content > hr {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 1px;
      background-color: var(--bg-footer);
      border: none;
      border-radius: 0;
      opacity: 0.1; }
  @media screen and (max-width: 87.5rem) {
    .footer-dropdown {
      width: 100%;
      position: relative;
      padding: 0.9375rem 0 0.875rem;
      border-bottom: solid 1px var(--stroke-footer); } }
  @media screen and (max-width: 87.5rem) and (min-width: 160.0625rem) {
    .footer-dropdown {
      padding: 0.58594vw 0 0.54688vw;
      border-bottom-width: 0.03906vw; } }
  @media screen and (max-width: 87.5rem) {
      .footer-dropdown > input[type=checkbox]:checked ~ .footer-dropdown__title::after {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg); }
      .footer-dropdown > input[type=checkbox]:checked ~ .footer-dropdown__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .footer-dropdown__content {
        display: none; }
      .footer-dropdown__title {
        position: relative;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        gap: 0.625rem;
        cursor: pointer; } }
    @media screen and (max-width: 87.5rem) and (min-width: 160.0625rem) {
      .footer-dropdown__title {
        gap: 0.39062vw; } }
  @media screen and (max-width: 87.5rem) {
        .footer-dropdown__title::before {
          content: "";
          position: absolute;
          top: -0.9375rem;
          left: -0.9375rem;
          width: calc(100% + 0.9375rem * 2);
          height: calc(100% + 0.9375rem * 2);
          background-color: transparent;
          opacity: 0; } }
      @media screen and (max-width: 87.5rem) and (min-width: 160.0625rem) {
        .footer-dropdown__title::before {
          top: -0.58594vw;
          left: -0.58594vw;
          width: calc(100% + 0.58594vw * 2);
          height: calc(100% + 0.58594vw * 2); } }
  @media screen and (max-width: 87.5rem) {
        .footer-dropdown__title::after {
          content: "";
          position: relative;
          width: 1.25rem;
          height: 1.25rem;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          background-color: var(--bg-footer);
          -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.99865 10.9766L14.1236 6.85156L15.302 8.0299L9.99865 13.3332L4.69531 8.0299L5.87365 6.85156L9.99865 10.9766Z' fill='white'/%3E%3C/svg%3E");
                  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.99865 10.9766L14.1236 6.85156L15.302 8.0299L9.99865 13.3332L4.69531 8.0299L5.87365 6.85156L9.99865 10.9766Z' fill='white'/%3E%3C/svg%3E");
          -webkit-mask-size: contain;
                  mask-size: contain;
          -webkit-mask-position: center;
                  mask-position: center;
          -webkit-mask-repeat: no-repeat;
                  mask-repeat: no-repeat;
          -webkit-transition: -webkit-transform var(--animation-timing) var(--cubic-bezier);
          transition: -webkit-transform var(--animation-timing) var(--cubic-bezier);
          -o-transition: transform var(--animation-timing) var(--cubic-bezier);
          transition: transform var(--animation-timing) var(--cubic-bezier);
          transition: transform var(--animation-timing) var(--cubic-bezier), -webkit-transform var(--animation-timing) var(--cubic-bezier); } }
      @media screen and (max-width: 87.5rem) and (min-width: 160.0625rem) {
        .footer-dropdown__title::after {
          width: 0.78125vw;
          height: 0.78125vw; } }

.contact-list__list {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.625rem;
  list-style: none; }
  @media screen and (min-width: 160.0625rem) {
    .contact-list__list {
      gap: 0.39062vw; } }

.contact-list__item,
.contact-list__item > .link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .contact-list__item,
    .contact-list__item > .link {
      gap: 0.39062vw; } }
  .contact-list__item::before,
  .contact-list__item > .link::before {
    content: "";
    position: absolute;
    top: -0.3125rem;
    left: -0.3125rem;
    width: calc(100% + 0.3125rem * 2);
    height: calc(100% + 0.3125rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .contact-list__item::before,
      .contact-list__item > .link::before {
        top: -0.19531vw;
        left: -0.19531vw;
        width: calc(100% + 0.19531vw * 2);
        height: calc(100% + 0.19531vw * 2); } }

@media (any-hover: hover) {
  .contact-list__item > .link:hover .contact-list__text {
    color: var(--accent-color-dark); }
  .contact-list__item > .link:hover .contact-list__icon > svg {
    fill: var(--accent-color-dark); } }

.contact-list__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .contact-list__content .select-current {
    background: none;
    padding: 0;
    border: 0; }
    .contact-list__content .select-current__text {
      color: var(--footer-text-primary); }
      .contact-list__content .select-current__text:hover {
        color: var(--accent-color); }
  .contact-list__content .select-answer {
    min-width: 25rem;
    color: var(--text-dark-primary); }
    @media screen and (max-width: 39.375rem) {
      .contact-list__content .select-answer {
        min-width: 15rem; } }
    .contact-list__content .select-answer .contact-list__text,
    .contact-list__content .select-answer .contact-list__title {
      color: var(--text-dark-primary); }
      .contact-list__content .select-answer .contact-list__text a,
      .contact-list__content .select-answer .contact-list__title a {
        color: var(--text-dark-primary);
        text-decoration: none; }
        .contact-list__content .select-answer .contact-list__text a:hover,
        .contact-list__content .select-answer .contact-list__title a:hover {
          color: var(--accent-color); }

.contact-list__text {
  color: var(--footer-text-primary);
  -webkit-transition: color var(--animation-timing) var(--cubic-bezier);
  -o-transition: color var(--animation-timing) var(--cubic-bezier);
  transition: color var(--animation-timing) var(--cubic-bezier); }

.contact-list__title {
  color: var(--footer-text-secondary); }
  .contact-list__title a:hover {
    color: var(--accent-color); }

.contact-list__icon {
  position: relative;
  padding: 0.53125rem 0.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: var(--radius-secondary);
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .contact-list__icon {
      padding: 0.33203vw 0.35156vw; } }
  .contact-list__icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-footer);
    opacity: 0.05; }
  .contact-list__icon > svg {
    position: relative;
    z-index: 1;
    width: 0.75rem;
    height: 0.75rem;
    fill: var(--bg-footer);
    -webkit-transition: fill var(--animation-timing) var(--cubic-bezier);
    -o-transition: fill var(--animation-timing) var(--cubic-bezier);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .contact-list__icon > svg {
        width: 0.46875vw;
        height: 0.46875vw; } }

.social-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .social-media {
      gap: 0.39062vw; } }
  .social-media__link {
    position: relative;
    border-color: var(--stroke-footer);
    background-color: transparent;
    -webkit-transition: border-color var(--animation-timing) var(--cubic-bezier);
    -o-transition: border-color var(--animation-timing) var(--cubic-bezier);
    transition: border-color var(--animation-timing) var(--cubic-bezier); }
    .social-media__link .button__icon {
      fill: var(--bg-footer); }
    @media (any-hover: hover) {
      .social-media__link:hover {
        border-color: var(--accent-color); } }
