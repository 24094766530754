.footer-dropdown {
  display: flex;
  flex-direction: column;
  gap: rem(15);

  @include mediaBigDesktop {
    gap: big(15);
  }

  &__title {
    color: var(--footer-text-primary);
  }

  &__icon {
    width: rem(16);
    height: rem(16);
    display: flex;
    flex-shrink: 0;

    fill: var(--bg-dark);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }
  }

  &__list {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    gap: rem(10);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__link {
    position: relative;

    &::before {
      @include pseudo(5) {}
    }

    &:not(.link_accent) {
      color: var(--footer-text-secondary);

      @include hover {
        color: var(--accent-color-dark);
      }
    }

    &.link_accent {
      color: var(--elements-orange);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    &>hr {
      padding: 0;
      margin: 0;

      width: 100%;
      height: 1px;

      background-color: var(--bg-footer);
      border: none;
      border-radius: 0;

      opacity: 0.1;
    }
  }

  @include mediaTablet {
    width: 100%;
    
    position: relative;
    padding: rem(15) 0 rem(14);

    border-bottom: solid 1px var(--stroke-footer);
    
    @include mediaBigDesktop {
      padding: big(15) 0 big(14);

      border-bottom-width: big(1);
    }

    &>input[type=checkbox] {
      &:checked {
        &~.footer-dropdown {
          &__title {
            &::after {
              transform: rotate(180deg);
            }
          }

          &__content {
            display: flex;
          }
        }
      }
    }

    &__content {
      display: none;
    }

    &__title {
      position: relative;

      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: rem(10);

      cursor: pointer;

      @include mediaBigDesktop {
        gap: big(10);
      }

      &::before {
        @include pseudo(15)
      }

      &::after {
        content: "";
        position: relative;

        width: rem(20);
        height: rem(20);
        display: flex;
        flex-direction: row;

        background-color: var(--bg-footer);
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.99865 10.9766L14.1236 6.85156L15.302 8.0299L9.99865 13.3332L4.69531 8.0299L5.87365 6.85156L9.99865 10.9766Z' fill='white'/%3E%3C/svg%3E");
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;

        transition: transform var(--animation-timing) var(--cubic-bezier);

        @include mediaBigDesktop {
          width: big(20);
          height: big(20);
        }
      }
    }
  }
}
