.payment-methods {
  display: flex;
  flex-direction: column;
  gap: rem(10);

  @include mediaBigDesktop {
    gap: big(10);
  }

  &__list {
    padding: 0;margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: rem(5);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(5);
    }
  }

  &__text {
    color: var(--footer-text-secondary);
  }

  &__item {
    position: relative;

    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;

    border-radius: var(--radius-secondary);
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;left: 0;
      z-index: 0;

      width: 100%;
      height: 100%;

      background-color: var(--bg-footer);
      opacity: 0.05;
    }
  }

  &__image {
    position: relative;
    z-index: 1;

    width: auto;
    height: rem(24);

    @include mediaBigDesktop {
      height: big(24);
    }
  }
}
