.contact-list {
  &__list {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    gap: rem(10);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__item,
  &__item>.link {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    &::before {
      @include pseudo(5) {}
    }
  }

  &__item>.link {
    @include hover {
      & .contact-list {
        &__text {
          color: var(--accent-color-dark);
        }
        &__icon {
          &>svg {
            fill: var(--accent-color-dark);
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    .select-current {
      background: none;
      padding: 0;
      border: 0;

      &__text {
        color: var(--footer-text-primary);

        &:hover {
          color: var(--accent-color);
        }
      }
    }

    .select-answer {
      min-width: 25rem;
      color: var(--text-dark-primary);

      @include mediaMobile {
        min-width: 15rem;
      }

      .contact-list__text,
      .contact-list__title {
        color: var(--text-dark-primary);

        a {
          color: var(--text-dark-primary);
          text-decoration: none;

          &:hover {
            color: var(--accent-color);
          }
        }
      }
    }
  }

  &__text {
    color: var(--footer-text-primary);

    transition: color var(--animation-timing) var(--cubic-bezier);
  }

  &__title {
    color: var(--footer-text-secondary);
    
    & a {
      &:hover {
        color: var(--accent-color);
      }
    }
  }

  &__icon {
    position: relative;
    padding: rem(8.5) rem(9);

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: var(--radius-secondary);
    overflow: hidden;

    @include mediaBigDesktop {
      padding: big(8.5) big(9);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      width: 100%;
      height: 100%;

      background-color: var(--bg-footer);
      opacity: 0.05;
    }

    &>svg {
      position: relative;
      z-index: 1;

      width: rem(12);
      height: rem(12);

      fill: var(--bg-footer);

      transition: fill var(--animation-timing) var(--cubic-bezier);

      @include mediaBigDesktop {
        width: big(12);
        height: big(12);
      }
    }
  }
}
