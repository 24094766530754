.social-media {
  display: flex;
  flex-direction: row;
  gap: rem(10);

  @include mediaBigDesktop {
    gap: big(10);
  }

  &__link {
    position: relative;

    border-color: var(--stroke-footer);
    background-color: transparent;

    transition: border-color var(--animation-timing) var(--cubic-bezier);

    & .button__icon {
      fill: var(--bg-footer);
    }

    @include hover {
      border-color: var(--accent-color);
    }
  }
}
